body,
html {
  font-size: 14px;
}

#root {
  height: 100%;
}

::-webkit-scrollbar {
  display: none;
}
scrollbar-width: none;
@import '~react-toastify/dist/ReactToastify.min';
